import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/bill-gold.jpg";
  const fullName = `William "Bill" Lees Gold`;
  const zoomUrl =
    "https://us02web.zoom.us/j/84150717769?pwd=OXBFbEx1dit2dklLM1Q2enR6aXdMUT09";
  const zoomPhoneNumber = "(778) 907-2071";
  const zoomMeetingId = "841 5071 7769";
  const zoomMeetingPasscode = "959677";
  const birthDate = new Date(1945, 1 - 1, 23);
  const deathDate = new Date(2021, 1 - 1, 9);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 1 - 1, 16);
  const memorialTime = "1pm EST";
  const inviteText =
    "William Lees Gold passed away on January 9, 2021. In lieu of a physical ceremony, we invite family and friends to celebrate his life in an online service.";
  const obituary = `William (Bill) Lees Gold passed away on January 9 of cancer. Survived by wife Margarita Puente Roca, children Tara Parker (Chris Owens), William Parker, Steven and Alan, brothers David and Robert (Isabel), niblings Sandra, Robert (Heather), and Irene, great-niblings Ashley, Trey, Rheya and Scotia. Predeceased by wife Margaret (née Beggs), parents William and Nellie, sister-in-law Marion, niblings Derek and Caroline, great-nibling Connor. Bill led his own life. Born in Scotland, he lived across North America, settling in Toronto with stops in Vancouver and Los Angeles. He worked in insurance and enjoyed a long retirement of travel and countless books. His thoughtful mentorship, sly smile and barbed humour will be missed.`;
  const storiesRecordUrl = "https://stories.afterword.com/fv8zr2j7g";
  const storiesSubmissionsUrl =
    "https://stories.afterword.com/roc04pm5k3w9hi1n4kkfjjpifn2xrdfyg9i5i1gc";
  const recordingEmbedUrl =
    "https://storage.googleapis.com/afterword-static/recordings/William%20Gold%20-%20recording.mp4";

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedUrl={recordingEmbedUrl}
    >
      <p>
        Donations in Bill’s memory can be made to the{" "}
        <a
          href="https://support.cancer.ca/site/Donation2?df_id=29383&mfc_pref=T&29383.donation=form1&s_locale=en_CA&s_orgGift=false&REGION=ON&s_qkey=undefined#_ga=2.254180744.1832304948.1610402813-636349884.1610402813"
          target="_blank"
          rel="noreferrer"
        >
          Canadian Cancer Society
        </a>{" "}
        or the{" "}
        <a
          href="https://donate.sunnybrook.ca/donate-now"
          target="_blank"
          rel="noreferrer"
        >
          Sunnybrook Foundation
        </a>
        .
      </p>
    </StoryPage>
  );
};

export default ThisPage;
